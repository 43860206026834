
footer {
	width:100%;
	background-color:rgba(29, 29, 29, 0.95);
	height:600px;
	background-position: bottom;
	background-attachment: fixed;
	background-repeat: none;
	background-size:cover;
}
footer:hover {
	cursor:default;
}
footer .container {
	position: static;
	bottom:0;
	flex: 0 0 100%;
	max-width: 100%;
	color:#fff;
	text-align:center;
	padding:1.2rem 1.8rem 1.5rem 1.8rem;
}
footer .container .row a {
	color:#fff;
}
footer .container .row h2 {
	color:#fff;
	margin-top:50px;
	font-size:4rem;
	font-weight:400;
	text-shadow: 3px 2px 2px rgba(0,0,0,0.25);
}
.footer-contact {
	text-align:center;
	margin:50px auto 3rem auto;
	transform-style: preserve-3d;
	background: url('./../img/profile.jpg') no-repeat;
	background-position: center center;
	background-size: cover;
	border: 3px solid #fff;
	border-radius: 200px;
	height:200px;
	width:200px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.19);
}
.footer-inner-contact {
	text-align:center;
	padding-top:11rem;
	border-radius: 200px;
	height:100%;
	opacity:0.85;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 8px 0 rgba(0, 0, 0, 0.1);
	background-image: linear-gradient(125deg, #2669ad30 0%, #e81aff2e 100%); 
  	transform: translateZ(25px) translateX(0%) translateY(0%);
}
.footer-contact-icons {
	text-align:center;
	margin:0 auto;
	width: 168px;
}
.footer-contact-icons a i {
	opacity:1;
	background-size:55px;
	height:55px;
	width:55px;
}
.footer-contact-icons a i.icon_email {
	background-image: url('./../img/icon/envelope-white.png');
	background-size:30px;
	margin-top:1px;
}
.footer-contact-icons a i.icon_github {
	background-image: url('./../img/icon/github.png');
}
.footer-contact-icons a i.icon_linkedin {
	background-image: url('./../img/icon/linkedin-white.png');
}
