.project-row div div h3 {
	font-size:3.2rem;
}
.project-row div .projects_wrapper_left {
	text-align:center;
}
.project-row div .projects_wrapper_right {
	margin-left:2rem;
	margin-right:2rem;
}
.project-row {
	margin-top:15px;
}
.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
	bottom:24px;
}
.swiper-pagination-bullet {
	width:9px;
	height:9px;
}