/* IntersectionObserver */
.animate {
	animation: anim 1.2s ease-out;
}
@keyframes anim {
	0% {
		transform: scale(0.9); 
		opacity: 0;
	}
	30% {
		transform: scale(0.95); 
		opacity: 0.5;
	}
	50% {
		transform: scale(0.98); 
		opacity: 0.7;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}