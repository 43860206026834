ul.skill-list {
	margin-bottom:30px;
}
ul.skill-list li {
	display: inline-block;
	border: 1px solid #dfdbdb;
	padding: 8px 10px;
	margin: 0 5px 5px 0;
	font-size: 1.3rem;
	font-weight:300;
	cursor: default;
	color:rgb(119, 119, 119);
}