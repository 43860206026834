:root {
	font-size: 62.5%; /* 16px font size */
}
#root {
	width:100%;
}
h1, h2, h3, h4, h5, h6, p, ul {
	font-family: 'Hind Madurai', sans-serif;
}
p {
	font-weight:300;
}
body {
	background: linear-gradient(-45deg, #a33ce7, #387cca, #2db190d2, #3a54e7);
	background-size: 2000% 500%;
	animation: gradientBG 30s ease infinite;
	margin:0;
	padding:0;
	display: grid;
	place-items: center;
	color:#444;
	font-size:1.5rem;

}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
a {
	color:#9a419a;
	text-decoration: none;
}
a:visited,a:hover {
	color:#456690;
}
h2 {
	color:#222;
	font-weight:400;
	font-size:2.6rem;
}
.clear {
	clear:both;
}
.container .main_content {
	background:#fff;
	border-radius: 3px 3px 4px 4px;
	box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2), 0 4px 16px 0 rgba(0, 0, 0, 0.19);
	text-align:left;
	padding-top:2.0rem;
	padding-bottom:2.0rem;
	margin-bottom:8rem;
}
.wrapper {
	text-align:center;
}
.column {
	flex-grow:1;
}
.content_inner p:last-child {
	margin-bottom:5rem;
}
i {
	font-style:normal;
}
.column-50 {
	flex: 0 0 50%;
	max-width: 50%;
}
.column-40 {
	flex: 0 0 40%;
	max-width: 40%;
}
.column-25 {
	flex: 0 0 25%;
	max-width: 25%;
}
.column-20 {
	flex: 0 0 20%;
	max-width: 20%;
}
.noBox {
	border:none !important;
	box-shadow:none !important;
}
.page-divider {
	width: 100%;
	height: 12px;
	background: rgba(53, 56, 60, 0.24);
}

@media only screen and (max-width: 640px) {
	main .container .row .column.column-80 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	main .container .row .column.column-90 {
		flex: 0 0 100%;
		max-width: 100%;
	}
	.main_content .container .row .column-75, .main_content .container .row .column-25, .main_content .container .row .column-50 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}