header h1 {
	cursor: default;
	margin:0 0 0 0;
	font-size:6.4rem;
	font-weight:500;
	line-height: 1;
}
header h1.page-title a {
	color:#fff;
}
header h3 {
	cursor: default;
	font-family: 'Dancing Script', serif;
	font-weight:normal;
	font-size:3.4rem;
	letter-spacing:0.1rem;
	margin-top:0px;
}
.header {
	width:720px;
	margin:18rem auto 12rem auto;
	text-align:center;
	color:#fff;
	padding:1.5rem 0;
	transform: perspective(1000px);
}
.header, .page-title, .page-subtitle {
	transform-style: preserve-3d;
}
#page-header {
	will-change: transform; 
}
.page-title {
	transform: translateZ(55px);
}
.page-subtitle {
	transform: translateZ(20px);
	color: transparent;
	opacity:0.8;
	background: linear-gradient(-90deg, #9bcffffb, #fab8e6f3, #ffe5beea, #b3ffd3dc, #b7d4ff);
	background-size: 80% 100%;
	background-clip:text;
	-webkit-background-clip:text;
	text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 820px) {
	.header {
		width:420px;
	}
}
@media only screen and (max-width: 640px) {
	.header {
		width:380px;
	}
}
@media only screen and (min-width: 1080px) {
	header h3 {
		font-weight:normal;
		margin-bottom:8rem;
	}
}
@media only screen and (max-width: 1080px) {
	header h1 {
		font-size:4.4rem;
	}
	header h3 {
		font-size:2.8rem;
	}
}